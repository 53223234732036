import Head from "../components/Head"
import GalleryItems from "../components/GalleryItems"

function Gallery (props) {
  const { finishLoading, viewMode } = props

  return (
    <>
      <Head schema="galleryMetadata" defaultPageName="Gallery" />
      <main className="page--gallery">
        <h1 className="sr-only">Gallery</h1>
        <GalleryItems finishLoading={finishLoading} viewMode={viewMode} schema="gallery" showCategories={true} />
      </main>
    </>
  )
}

export default Gallery
