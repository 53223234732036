import { createClient } from "@sanity/client"

const client = createClient({
  projectId: "8rscj55j",
  dataset: "production",
  apiVersion: "2024-04-30",
  useCdn: true
})

export default client
