import "./Footer.css"
import Socials from "./Socials"
import Copyright from "./Copyright"

function Footer () {
  return (
    <footer>
      <Socials schema="socials" />
      <Copyright />
    </footer>
  )
}

export default Footer
