import "./Loading.css"

function Loading () {
  return (
    <div className="loading-panel">
      <span className="loader"></span>
    </div>
  )
}

export default Loading
