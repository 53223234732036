import { useState, useCallback, useEffect } from "react"
import { NavLink } from "react-router-dom"

function Nav () {
  const [ activePage, setActivePage ] = useState(null)

  const handleClick = useCallback(e => {
    setActivePage(e.currentTarget)
  }, [])

  useEffect(() => {
    const navLinks = Array.from(document.querySelectorAll("header a"))
    navLinks.forEach(link => {
      link.addEventListener("click", handleClick)
    })
    return () => {
      navLinks.forEach(link => {
        link.removeEventListener("click", handleClick)
      })
    }
  }, [handleClick])

  useEffect(() => {
    if (activePage) {
      window.scrollTo(0, 0)
      activePage.blur()
      activePage.tabIndex = -1
      const navLinks = Array.from(document.querySelectorAll("header a"))
      navLinks.filter(link => link !== activePage).forEach(link => {
        link.removeAttribute("tabindex")
      })
    }
  }, [activePage])

  useEffect(() => {
    setActivePage(document.querySelector("header a.active"))
  }, [])

  return (
    <nav className="main-nav">
      <ul>
        <li>
          <NavLink to="/about" className="color-dark-salmon">About</NavLink>
        </li>
        <li>
          <NavLink to="/gallery" className="color-dark-fawn">Gallery</NavLink>
        </li>
        <li>
          <NavLink to="/tarot" className="color-dark-cambridge-blue">Tarot</NavLink>
        </li>
        <li>
          <NavLink to="/collaborations" className="color-dark-cool-gray">Collaborations</NavLink>
        </li>
        <li>
          <NavLink to="/facilitation" className="color-dark-salmon">Facilitation</NavLink>
        </li>
        <li>
          <NavLink to="/embroidery" className="color-dark-fawn">Embroidery</NavLink>
        </li>
        <li>
          <NavLink to="/contact" className="color-dark-cambridge-blue">Contact</NavLink>
        </li>
      </ul>
    </nav>
  )
}

export default Nav
