import { useQuery } from "react-query"
import { PortableText } from "@portabletext/react"
import { getBlockContent } from "../utils/api"
import Head from "../components/Head"
import Error from "../components/Error"
import GalleryItems from "../components/GalleryItems"

function Embroidery (props) {
  const { finishLoading, viewMode } = props
  const { isLoading, error, data } = useQuery("embroideryIntro", () => getBlockContent("embroideryIntro"))

  if (error) return <Error message={error.message} />
  if (isLoading) return

  return (
    <>
      <Head schema="embroideryMetadata" defaultPageName="Embroidery" />
      <main className="page--embroidery">
        <h1 className="sr-only">Embroidery</h1>
        { data.length
        ? <div className="wrapper-md flow">
            { data.map((item, index) =>
              <PortableText key={index} value={item.blockContent} />
            ) }
          </div>
        : null
        }
        <GalleryItems finishLoading={finishLoading} viewMode={viewMode} schema="embroideryGallery" />
      </main>
    </>
  )
}

export default Embroidery
