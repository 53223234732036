import { useState, useCallback, useEffect } from "react"
import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import Loading from "./Loading"
import Home from "../pages/Home"
import About from "../pages/About"
import Gallery from "../pages/Gallery"
import Tarot from "../pages/Tarot"
import Collaborations from "../pages/Collaborations"
import Facilitation from "../pages/Facilitation"
import Embroidery from "../pages/Embroidery"
import Contact from "../pages/Contact"

function Layout (props) {
  const { page } = props
  const [ showLoader, setShowLoader ] = useState(true)
  const [ viewMode, setViewMode ] = useState(
    window.innerWidth < 600 ? "phone" : 
    window.innerWidth < 900 ? "tablet-portrait" : 
    window.innerWidth < 1200 ? "tablet-landscape" : 
    window.innerWidth < 1500 ? "small-desktop" : 
    window.innerWidth < 1800 ? "desktop" : 
    "big-desktop"
  )

  const finishLoading = useCallback(() => {
    const loadingPanel = document.getElementsByClassName("loading-panel")[0]
    loadingPanel?.classList.add("extended-fade-out")
    setTimeout(() => setShowLoader(false), 1000)
  }, [])

  const handleResize = useCallback(() => {
    switch (true) {
      case window.innerWidth < 600:
        if (viewMode !== "phone") setViewMode("phone")
        break
      case window.innerWidth < 900:
        if (viewMode !== "tablet-portrait") setViewMode("tablet-portrait")
        break
      case window.innerWidth < 1200:
        if (viewMode !== "tablet-landscape") setViewMode("tablet-landscape")
        break
      case window.innerWidth < 1500:
        if (viewMode !== "small-desktop") setViewMode("small-desktop")
        break
      case window.innerWidth < 1800:
        if (viewMode !== "desktop") setViewMode("desktop")
        break
      default:
        if (viewMode !== "big-desktop") setViewMode("big-desktop")
    }
  }, [viewMode])

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [handleResize])

  const pageMap = {
    home: <Home finishLoading={finishLoading} />,
    about: <About finishLoading={finishLoading} />,
    gallery: <Gallery finishLoading={finishLoading} viewMode={viewMode} />,
    tarot: <Tarot finishLoading={finishLoading} viewMode={viewMode} />,
    collaborations: <Collaborations finishLoading={finishLoading} viewMode={viewMode} />,
    facilitation: <Facilitation finishLoading={finishLoading} viewMode={viewMode} />,
    embroidery: <Embroidery finishLoading={finishLoading} viewMode={viewMode} />,
    contact: <Contact finishLoading={finishLoading} />,
  }

  return (
    <>
      <div id="page-top"></div>
      { showLoader ? <Loading /> : null }
      { ["desktop", "big-desktop"].includes(viewMode) ? <Sidebar viewMode={viewMode} /> : <Header viewMode={viewMode} /> }
      { pageMap[page] || <Home /> }
      { ["desktop", "big-desktop"].includes(viewMode) ? null : <Footer /> }
    </>
  )
}

export default Layout
