import { BrowserRouter, Routes, Route } from "react-router-dom"
import { QueryClient, QueryClientProvider } from 'react-query'
import { HelmetProvider } from "react-helmet-async"
import "./App.css"
import Redirect from "./components/Redirect"
import Layout from "./components/Layout"

const queryClient = new QueryClient()

function App () {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/admin" element={<Redirect url="https://jessdevitt.sanity.studio" />} />
            <Route exact path="/" element={<Layout page="home" />} />
            <Route path="/about" element={<Layout page="about" />} />
            <Route path="/gallery" element={<Layout page="gallery" />} />
            <Route path="/tarot" element={<Layout page="tarot" />} />
            <Route path="/collaborations" element={<Layout page="collaborations" />} />
            <Route path="/facilitation" element={<Layout page="facilitation" />} />
            <Route path="/embroidery" element={<Layout page="embroidery" />} />
            <Route path="/contact" element={<Layout page="contact" />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </HelmetProvider>
  )
}

export default App
