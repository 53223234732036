function Error (props) {
  const { message } = props

  return (
    <main className="error">
      { message
      ? <p>An error has occurred: {message}</p>
      : <p>An unknown error has ocurred.</p>
      }
    </main>
  )
}

export default Error
