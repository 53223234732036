import { Helmet } from "react-helmet-async"
import { useQuery } from "react-query"
import { getMetadata } from "../utils/api"
import Error from "./Error"

function Head (props) {
  const { schema, defaultPageName } = props
  const { isLoading, error, data } = useQuery(schema, () => getMetadata(schema, defaultPageName))

  if (error) return <Error message={error.message} />
  if (isLoading) return

  // const { favicon, pageTitle, metaAuthor, metaDescription, metaImage } = data[0]
  const { pageTitle, metaAuthor, metaDescription, metaImage } = data[0]

  return (
    <Helmet>
      { pageTitle
      ? <>
          <title>{pageTitle}</title>
          <meta name="title" content={pageTitle} />
        </>
      : null
      }
      { metaAuthor ? <meta name="author" content={metaAuthor} /> : null }
      { metaDescription ? <meta name="description" content={metaDescription} /> : null }
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.origin + window.location.pathname} />
      { pageTitle ? <meta property="og:title" content={pageTitle} /> : null }
      { metaDescription ? <meta property="og:description" content={metaDescription} /> : null }
      { metaImage?.url ? <meta property="og:image" content={metaImage.url} /> : null }
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={window.location.origin + window.location.pathname} />
      { pageTitle ? <meta property="twitter:title" content={pageTitle} /> : null }
      { metaDescription ? <meta property="twitter:description" content={metaDescription} /> : null }
      { metaImage?.url ? <meta property="twitter:image" content={metaImage.url} /> : null }
      <link rel="canonical" href={window.location.origin + window.location.pathname} />
    </Helmet>
  )
}

export default Head
