import { useEffect } from "react"
import { NavLink } from "react-router-dom"
import { useQuery } from "react-query"
import { getImage } from "../utils/api"
import "./Header.css"
import HamburgerMenu from "./HamburgerMenu"
import Nav from "./Nav"
import Image from "../components/Image"
import signature from "../assets/images/signature.png"

function Header (props) {
  const { viewMode } = props
  const { error, data } = useQuery("logo", () => getImage("logo"))

  useEffect(() => {
    const header = document.getElementsByTagName("header")[0]
    if (header) {
      const observer = new IntersectionObserver(entries => {
        const menu = document.getElementById("mobile-menu")
        const isOpen = menu?.classList.contains("is-open")
        if (!entries[0].isIntersecting && !isOpen) {
          header.classList.add("small")
        } else {
          header.classList.remove("small")
        }
      }, { threshold: 0 })
      if (["phone", "tablet-portrait", "tablet-landscape", "small-desktop"].includes(viewMode)) {
        const pageTop = document.getElementById("page-top")
        observer.observe(pageTop)
      }
      return () => observer.disconnect()
    }
  }, [viewMode])

  return (
    <>
      { ["phone", "tablet-portrait"].includes(viewMode)
      ? <header className="mobile">
          <NavLink to="/">
            { error
            ? <img className="logo" src={signature} alt="Jess De Vitt's signature." />
            : null
            }
            { data?.map((item, index) =>
              <Image key={index} className="logo" id={item.image.id} preview={item.image.preview} alt={item.image.alt} />
            ) }
            <span className="sr-only">Home</span>
          </NavLink>
          <HamburgerMenu />
        </header>
      : <header>
          <NavLink to="/">
            { error
            ? <img className="logo" src={signature} alt="Jess De Vitt's signature." />
            : null
            }
            { data?.map((item, index) =>
              <Image key={index} className="logo" id={item.image.id} preview={item.image.preview} alt={item.image.alt} />
            ) }
            <span className="sr-only">Home</span>
          </NavLink>
          <Nav />
        </header>
      }
    </>
  )
}

export default Header
