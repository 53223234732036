import { useRef, useCallback, useEffect } from "react"
import { PortableText } from "@portabletext/react"
import { portableTextComponents, runAfterRepaint, trapFocus } from "../utils/helpers"
import "./Modal.css"
import Image from "./Image"

const prevIndex = (index, array) => (index + array.length - 1) % array.length
const nextIndex = (index, array) => (index + 1) % array.length

function toggleDetails (target) {
  target.closest(".text-container").classList.toggle("closed")
}

function Modal (props) {
  const { data, initialIndex, closeModal } = props
  const lockSlide = useRef(false)

  const toPrev = useCallback(() => {
    if (lockSlide.current) return
    lockSlide.current = true
    const slides = Array.from(document.getElementsByClassName("modal"))
    const currentIndex = slides.findIndex(slide => slide.classList.contains("current-modal"))
    const currentSlide = slides[currentIndex]
    const nextSlide = slides[nextIndex(currentIndex, slides)]
    const prevSlide = slides[prevIndex(currentIndex, slides)]
    const newPrevSlide = slides[prevIndex(prevIndex(currentIndex, slides), slides)]
    nextSlide.classList.remove("next-modal")
    prevSlide.classList.remove("no-display")
    newPrevSlide.classList.add("previous-modal")
    runAfterRepaint(() => {
      prevSlide.classList.remove("previous-modal")
      prevSlide.classList.add("current-modal")
      currentSlide.classList.remove("current-modal")
      currentSlide.classList.add("next-modal")
      setTimeout(() => {
        currentSlide.classList.add("no-display")
        lockSlide.current = false
      }, 300)
    })()
  }, [])

  const toNext = useCallback(() => {
    if (lockSlide.current) return
    lockSlide.current = true
    const slides = Array.from(document.getElementsByClassName("modal"))
    const currentIndex = slides.findIndex(slide => slide.classList.contains("current-modal"))
    const currentSlide = slides[currentIndex]
    const prevSlide = slides[prevIndex(currentIndex, slides)]
    const nextSlide = slides[nextIndex(currentIndex, slides)]
    const newNextSlide = slides[nextIndex(nextIndex(currentIndex, slides), slides)]
    prevSlide.classList.remove("previous-modal")
    nextSlide.classList.remove("no-display")
    newNextSlide.classList.add("next-modal")
    runAfterRepaint(() => {
      nextSlide.classList.remove("next-modal")
      nextSlide.classList.add("current-modal")
      currentSlide.classList.remove("current-modal")
      currentSlide.classList.add("previous-modal")
      setTimeout(() => {
        currentSlide.classList.add("no-display")
        lockSlide.current = false
      }, 300)
    })()
  }, [])
  
  const handleKeydown = useCallback(e => {
    switch (e.key) {
      case "Escape":
        closeModal()
        break
      case "Left":
      case "ArrowLeft":
        if (data.length > 1) toPrev()
        break
      case "Right":
      case "ArrowRight":
        if (data.length > 1) toNext()
        break
      default:
    }
  }, [data, closeModal, toPrev, toNext])

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown)
    return () => document.removeEventListener("keydown", handleKeydown)
  }, [handleKeydown])

  useEffect(() => {
    trapFocus.trap(document.getElementsByClassName("modal-backdrop")[0])
    return () => trapFocus.release()
  }, [])

  return (
    <div className="modal-backdrop">
      { data.map((item, index) =>
        <article key={index} className={index === initialIndex || index === prevIndex(index, data) ? "modal current-modal" : index === prevIndex(initialIndex, data) ? "modal previous-modal no-display" : index === nextIndex(initialIndex, data) ? "modal next-modal no-display" : "modal no-display"}>
          <div className="image-container">
            <Image id={item.image.id} preview={item.image.preview} alt={item.image.alt} />
          </div>
          <div className="text-container closed">
            <button className="button details-button" type="button" onClick={e => toggleDetails(e.target)}>
              Details
              <i className="icon-arrow-down" />
            </button>
            { item.title ? <h2>{item.title}</h2> : null }
            <div>
              { item.publishedAt ? <span><strong>Year:</strong> {new Date(item.publishedAt).getFullYear()}</span> : null }
              { item.medium ? <span><strong>Medium:</strong> {item.medium}</span> : null }
            </div>
            { item.description ? <PortableText value={item.description} components={portableTextComponents} /> : null }
          </div>
        </article>
      ) }
      <button id="close-modal" className="modal-button" type="button" onClick={closeModal}>
        <i className="icon-close" />
        <span className="sr-only">Close modal</span>
      </button>
      { data.length > 1
      ? <>
          <button id="to-previous-modal" className="modal-button" type="button" onClick={toPrev}>
            <i className="icon-arrow-left" />
            <span className="sr-only">Previous image</span>
          </button>
          <button id="to-next-modal" className="modal-button" type="button" onClick={toNext}>
            <i className="icon-arrow-right" />
            <span className="sr-only">Next image</span>
          </button>
        </>
      : null
      }
    </div>
  )
}

export default Modal
