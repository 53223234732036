import { useQuery } from "react-query"
import { PortableText } from "@portabletext/react"
import { getBlockContent } from "../utils/api"
import Head from "../components/Head"
import Error from "../components/Error"
import GalleryItems from "../components/GalleryItems"

function Tarot (props) {
  const { finishLoading, viewMode } = props
  const { isLoading, error, data } = useQuery("tarotIntro", () => getBlockContent("tarotIntro"))

  if (error) return <Error message={error.message} />
  if (isLoading) return

  return (
    <>
      <Head schema="tarotMetadata" defaultPageName="Tarot" />
      <main className="page--tarot">
        <h1 className="sr-only">Tarot</h1>
        { data.length
        ? <div className="wrapper-md flow">
            { data.map((item, index) =>
              <PortableText key={index} value={item.blockContent} />
            ) }
          </div>
        : null
        }
        <GalleryItems finishLoading={finishLoading} viewMode={viewMode} schema="tarotGallery" />
      </main>
    </>
  )
}

export default Tarot
