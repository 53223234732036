import { trimStrings, encodeMetaImages } from "./helpers"
import sanityClient from "../client"

function getString (schema) {
  if (!schema) throw new Error("Schema name is required!")
  return sanityClient
    .fetch(`
      *[_type == "${schema}"]{
        text
      }
    `)
    .then(data => trimStrings(data))
    .catch(error => console.log(`${error}\nCould not fetch data from schema "${schema}"`))
}

function getBlockContent (schema) {
  if (!schema) throw new Error("Schema name is required!")
  return sanityClient
    .fetch(`
      *[_type == "${schema}"]{
        blockContent
      }
    `)
    .then(data => trimStrings(data))
    .catch(error => console.log(`${error}\nCould not fetch data from schema "${schema}"`))
}

function getImage (schema) {
  if (!schema) throw new Error("Schema name is required!")
  return sanityClient
    .fetch(`
      *[_type == "${schema}"]{
        image{
          "id": asset._ref,
          "preview": asset->metadata.lqip,
          "aspectRatio": asset->metadata.dimensions.aspectRatio,
          hotspot { x, y },
          crop {
            bottom,
            left,
            right,
            top,
          },
          alt
        }
      }
    `)
    .then(data => trimStrings(data))
    .catch(error => console.log(`${error}\nCould not fetch data from schema "${schema}"`))
}

function getImageWithCaption (schema) {
  if (!schema) throw new Error("Schema name is required!")
  return sanityClient
    .fetch(`
      *[_type == "${schema}"]{
        image{
          "id": asset._ref,
          "preview": asset->metadata.lqip,
          "aspectRatio": asset->metadata.dimensions.aspectRatio,
          hotspot { x, y },
          crop {
            bottom,
            left,
            right,
            top,
          },
          alt
        },
        caption
      }
    `)
    .then(data => trimStrings(data))
    .catch(error => console.log(`${error}\nCould not fetch data from schema "${schema}"`))
}

function getCategories (schema, itemsSchema) {
  if (!schema) throw new Error("Schema name is required!")
  return sanityClient
    .fetch(`
      *[_type == "${schema}"]{
        name
      }
    `)
    .then(async (data) => {
      trimStrings(data)
      if (itemsSchema) {
        await getGalleryItems(itemsSchema).then(items => {
          data.forEach(category => {
            category.hasItems = !!items.filter(item => item.categories && item.categories.includes(category.name)).length
          })
        })
      }
      return data
    })
    .catch(error => console.log(`${error}\nCould not fetch data from schema "${schema}"`))
}

function getGalleryItems (schema) {
  if (!schema) throw new Error("Schema name is required!")
  return sanityClient
    .fetch(`
      *[_type == "${schema}"]{
        title,
        image{
          "id": asset._ref,
          "preview": asset->metadata.lqip,
          "aspectRatio": asset->metadata.dimensions.aspectRatio,
          hotspot { x, y },
          crop {
            bottom,
            left,
            right,
            top,
          },
          alt
        },
        "categories": categories[]->name,
        publishedAt,
        medium,
        description
      }
    `)
    .then(data => trimStrings(data))
    .catch(error => console.log(`${error}\nCould not fetch data from schema "${schema}"`))
}

async function getMetadata (schema, defaultPageName) {
  if (!schema) throw new Error("Schema name is required!")
  const siteMetadata = await sanityClient
    .fetch(`
      *[_type == "siteMetadata"]{
        favicon,
        pageTitleTemplate,
        pageName,
        metaAuthor,
        metaDescription,
        metaImage{
          asset->{
            _id,
            url
          },
          crop{
            top,
            bottom,
            left,
            right
          }
        }
      }
    `)
    .then(data => {
      trimStrings(data)
      encodeMetaImages(data)
      if (!data.length) data.push({})
      return data.map(item => {
        item.pageTitle = item.pageTitleTemplate?.replaceAll("{Page}", (item.pageName || "Home")) || item.pageName || "Home"
        return item
      })
    })
    .catch(error => console.log(`${error}\nCould not fetch data from schema "siteMetadata"`))
  if (schema === "siteMetadata") return siteMetadata
  if (!defaultPageName) throw new Error("Default page name is required!")
  return sanityClient
    .fetch(`
      *[_type == "${schema}"]{
        pageName,
        metaDescription,
        metaImage{
          asset->{
            _id,
            url
          },
          crop{
            top,
            bottom,
            left,
            right
          }
        }
      }
    `)
    .then(data => {
      trimStrings(data)
      encodeMetaImages(data)
      if (!data.length) data.push({})
      return [{...siteMetadata[0], ...data.map(item => {
        item.pageTitle = siteMetadata[0].pageTitleTemplate?.replaceAll("{Page}", (item.pageName || defaultPageName)) || item.pageName || defaultPageName || null
        item.metaDescription = item.metaDescription || siteMetadata[0].metaDescription || null
        if (!item.metaImage) item.metaImage = siteMetadata[0].metaImage
        return item
      })[0]}]
    })
    .catch(error => {
      console.log(`${error}\nCould not fetch data from schema "${schema}"`)
      return siteMetadata
    })
}

function getSocials (schema) {
  if (!schema) throw new Error("Schema name is required!")
  return sanityClient
    .fetch(`
      *[_type == "${schema}"]{
        facebook{
          url,
          active,
          "name": "Facebook",
          "iconClass": "icon-facebook"
        },
        instagram{
          url,
          active,
          "name": "Instagram",
          "iconClass": "icon-instagram"
        },
        linkedin{
          url,
          active,
          "name": "LinkedIn",
          "iconClass": "icon-linkedin"
        },
        linktree{
          url,
          active,
          "name": "Linktree",
          "iconClass": "icon-linktree"
        },
        pinterest{
          url,
          active,
          "name": "Pinterest",
          "iconClass": "icon-pinterest"
        },
        tiktok{
          url,
          active,
          "name": "TikTok",
          "iconClass": "icon-tiktok"
        },
        twitter{
          url,
          active,
          "name": "Twitter",
          "iconClass": "icon-twitter"
        },
        x{
          url,
          active,
          "name": "X",
          "iconClass": "icon-x"
        }
      }
    `)
    .then(data => trimStrings(data))
    .catch(error => console.log(`${error}\nCould not fetch data from schema "${schema}"`))
}

export { getString, getBlockContent, getImage, getImageWithCaption, getCategories, getGalleryItems, getMetadata, getSocials }
