import Image from "../components/Image"

const SANITY_IMG_URL_PATTERN = /^image-([a-f\d]+)-(\d+x\d+)-(\w+)$/

const portableTextComponents = {
  marks: {
    link: ({value, children}) => {
      const href = value?.href || ""
      const target = href.startsWith("http") ? "_blank" : undefined
      return (
        href.includes("jessdevitt.com")
        ? <a href={(href.split("jessdevitt.com")[1] || "/")}>
            {children}
          </a>
        : <a href={href} target={target} rel={target === "_blank" && "noreferrer"}>
            {children}
          </a>
      )
    }
  },
  types: {
    customImage: ({ value }) => <Image id={value.asset._ref} alt={value.alt} />
  }
}

function decodeAssetId (id) {
  const [ , assetId, dimensions, format ] = SANITY_IMG_URL_PATTERN.exec(id)
  const [ width, height ] = dimensions.split("x").map(value => parseInt(value, 10))
  return {
    assetId,
    dimensions: { width, height },
    format
  }
}

function trimStrings (arg) {
  if (Array.isArray(arg)) {
    arg.forEach(item => item = trimStrings(item))
  } else if (typeof arg === "object" && arg !== null && (!("_type" in arg) || arg._type !== "block")) {
    for (const property in arg) arg[property] = trimStrings(arg[property])
  } else if (typeof arg === "string" || arg instanceof String) {
    return arg.trim()
  }
  return arg
}

function encodeMetaImages (data) {
  return data.map(item => {
    if (!item.metaImage) return item
    const { width, height } = decodeAssetId(item.metaImage.asset._id).dimensions
    if (!(width && height && item.metaImage.asset.url)) throw new Error("Cannot parse image")
    let left, top, croppedWidth, croppedHeight
    if (item.metaImage.crop) {
      left = Math.floor(width * item.metaImage.crop.left)
      top = Math.floor(height * item.metaImage.crop.top)
      croppedWidth = Math.floor(width * (1 - (item.metaImage.crop.right + item.metaImage.crop.left)))
      croppedHeight = Math.floor(height * (1 - (item.metaImage.crop.top + item.metaImage.crop.bottom)))
    }
    item.metaImage.url = item.metaImage.asset.url + `?w=1200&y=630&q=100&fit=min${left || top || croppedWidth || croppedHeight ? `&rect=${left},${top},${croppedWidth},${croppedHeight}` : ""}&fm=png`
    return item
  })
}

function getRandomInt (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

function getRandomImageObjects (n, minWidth, maxWidth, minHeight, maxHeight) {
  const imageObjects = new Array(n)
  imageObjects.forEach((obj, i) => {
    obj = {
      src: `https://source.unsplash.com/random/${this.getRandomInt(minWidth, maxWidth)}00x${this.getRandomInt(minHeight, maxHeight)}00?sig=${i + 1}`,
      alt: `Image number ${i + 1}`,
      title: `Image number ${i + 1} title`,
      year: this.getRandomInt(2005, 2022),
      medium: "oil on canvas",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nulla facilisi morbi tempus iaculis urna id. Risus commodo viverra maecenas accumsan lacus vel facilisis volutpat."
    }
  })
  return imageObjects
}

function runAfterRepaint (func) {
  return function () {
    const context = this, args = arguments
    requestAnimationFrame(() => {
      setTimeout(() => {
        func.apply(context, args)
      }, 0)
    })
  }
}

let trapFocus = {
  /**
   * Trap tab focus within the passed element.
   * 
   * @param {Element} element The parent element which tab focus should be trapped within.
   * @param {Boolean} inclusive Whether the passed element should be focusable.
   */
  trap: function (element, inclusive = false) {
    // guard clauses
    if (!(element instanceof Element)) throw new Error("First parameter must be an Element")
    if (typeof inclusive !== "boolean") throw new Error("Second parameter must be a boolean")

    // release any disabled elements
    if ("disabledEls" in this) {
      this.release()
    } else {
      this.disabledEls = []
    }

    // function to disable the passed element
    const disableEl = el => {
      el.dataset.tabindex = el.hasAttribute("tabindex") ? el.tabIndex : "none"
      el.tabIndex = "-1"
      this.disabledEls.push(el)
    }

    // css selector for all focusable elements
    const focusableSelector = "a[href]:not([disabled]):not([tabindex^='-']), area[href]:not([disabled]):not([tabindex^='-']), input:not([disabled]):not([tabindex^='-']), select:not([disabled]):not([tabindex^='-']), textarea:not([disabled]):not([tabindex^='-']), button:not([disabled]):not([tabindex^='-']), iframe:not([tabindex^='-']), [tabindex]:not([tabindex^='-']), [contentEditable=true]:not([tabindex^='-'])"

    // disable all focusable elements outside the target
    const focusableEls = [...document.querySelectorAll(focusableSelector)]
    const elsToDisable = inclusive ? focusableEls.filter(el => !element.contains(el)) : focusableEls.filter(el => el !== element && !element.contains(el))
    elsToDisable.forEach(el => disableEl(el))

    // mutator function for mutation observer
    const mutate = mutations => {
      mutations.forEach(mutation => {
        mutation.addedNodes.forEach(node => {
          if (node.matches(focusableSelector) && inclusive ? !element.contains(node) : node !== element && !element.contains(node)) {
            disableEl(node)
          }
        })
      })
    }

    // create mutation observer singleton
    if (!("observer" in this)) this.observer = new MutationObserver(mutate)

    // observe new elements and disable them if necessary
    this.observer.observe(document.body, { childList: true, subtree: true })
  },
  /**
   * Release all trapped elements.
   */
  release: function () {
    // disconnect the observer if necessary
    if ("observer" in this) this.observer.disconnect()

    // release any disabled elements
    if ("disabledEls" in this) {
      this.disabledEls.forEach(el => {
        if (el.dataset.tabindex === "none") {
          el.removeAttribute("tabindex")
        } else {
          el.tabIndex = el.dataset.tabindex
        }
        el.removeAttribute("data-tabindex")
      })
      this.disabledEls = []
    }
  }
}

function debounce (func, delay, immediate = false) {
  if (!("timeout" in this)) this.timeout = undefined
  return function () {
    const context = this, args = arguments
    const callNow = immediate && !this.timeout
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.timeout = null
      if (!immediate) func.apply(context, args)
    }, delay)
    if (callNow) func.apply(context, args)
  }
}

export { portableTextComponents, decodeAssetId, trimStrings, encodeMetaImages, getRandomInt, getRandomImageObjects, runAfterRepaint, trapFocus, debounce }
