import { useRef, useEffect } from "react"
import { useQuery } from "react-query"
import { getSocials } from "../utils/api"
import "./Socials.css"
import Error from "./Error"

function Socials (props) {
  const { schema } = props
  const { isLoading, error, data } = useQuery(schema, () => getSocials(schema))
  const activeSocials = useRef([])

  useEffect(() => {
    if (data) {
      activeSocials.current = Object.keys(data[0]).map(key => data[0][key]).filter(item => item.active && item.url?.length).sort((a, b) => a.name.localeCompare(b.name))
    }
  }, [data])

  if (error) return <Error message={error.message} />
  if (isLoading) return

  return (
    <ul className="social-links">
      { activeSocials.current.map((item, index) => 
        <li key={index}>
          <a href={item.url} target="_blank" rel="noreferrer">
            <i className={item.iconClass} />
            <span className="sr-only">{item.name}</span>
          </a>
        </li>
      ) }
    </ul>
  )
}

export default Socials
