import "./Sidebar.css"
import Header from "./Header"
import Footer from "./Footer"

function Sidebar () {
  return (
    <div className="sidebar">
      <Header />
      <Footer />
    </div>
  )
}

export default Sidebar
