import { useEffect } from "react"
import { runAfterRepaint, trapFocus } from "../utils/helpers"
import "./HamburgerMenu.css"
import Nav from "./Nav"
import Socials from "./Socials"
import Copyright from "./Copyright"

function openMenu () {
  const menu = document.getElementById("mobile-menu")
  const isOpen = menu.classList.contains("is-open")
  if (isOpen) return
  const body = document.body
  const icon = document.querySelector("#menu-toggle i")
  const hamburgerMenu = document.getElementsByClassName("hamburger-menu")[0]
  icon.classList.add("is-toggling")
  setTimeout(() => {
    body.classList.add("no-scroll")
    icon.classList.remove("icon-menu")
    icon.classList.add("icon-close")
    menu.classList.remove("no-display")
    runAfterRepaint(() => {
      menu.classList.add("is-open")
      trapFocus.trap(hamburgerMenu)
      setTimeout(() => {
        icon.classList.remove("is-toggling")
      }, 150)
    })()
  }, 150)
}

function closeMenu () {
  const menu = document.getElementById("mobile-menu")
  const isOpen = menu.classList.contains("is-open")
  if (!isOpen) return
  const body = document.body
  const icon = document.querySelector("#menu-toggle i")
  icon.classList.add("is-toggling")
  setTimeout(() => {
    icon.classList.remove("icon-close")
    icon.classList.add("icon-menu")
    menu.classList.remove("is-open")
    trapFocus.release()
    setTimeout(() => {
      body.classList.remove("no-scroll")
      menu.classList.add("no-display")
      icon.classList.remove("is-toggling")
    }, 150)
  }, 150)
}

function toggleMenu () {
  const menu = document.getElementById("mobile-menu")
  const isOpen = menu.classList.contains("is-open")
  if (isOpen) {
    closeMenu()
  } else {
    openMenu()
  }
}

function HamburgerMenu () {
  useEffect(() => {
    const navLinks = document.querySelectorAll("header a")
    navLinks.forEach(link => link.addEventListener("click", closeMenu))
    return () => navLinks.forEach(link => link.removeEventListener("click", closeMenu))
  }, [])

  return (
    <div className="hamburger-menu">
      <button id="menu-toggle" className="menu-button" type="button" onClick={toggleMenu}>
        <i className="icon-menu" /><span className="sr-only">Open menu</span>
      </button>
      <div id="mobile-menu" className="no-display">
        <Nav />
        <Socials schema="socials" />
        <Copyright />
      </div>
    </div>
  )
}

export default HamburgerMenu
