import { useQuery } from "react-query"
import { getMetadata } from "../utils/api"
import Error from "./Error"

const year = new Date().getFullYear()

function Copyright () {
  const { isLoading, error, data } = useQuery("siteMetadata", () => getMetadata("siteMetadata"))

  if (error) return <Error message={error.message} />
  if (isLoading) return

  return (
    <>
      { data.length
      ? <p className="copyright">Copyright &copy; { year } { data[0].metaAuthor || "Jess De Vitt" }</p>
      : null
      }
    </>
  )
}

export default Copyright
