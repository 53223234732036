import { useEffect } from "react"
import { useQuery } from "react-query"
import { PortableText } from "@portabletext/react"
import { getImageWithCaption } from "../utils/api"
import { portableTextComponents } from "../utils/helpers"
import "./Home.css"
import Head from "../components/Head"
import Image from "../components/Image"
import tierra from "../assets/images/Tierra.png"

function Home (props) {
  const { finishLoading } = props
  const { isLoading, error, data } = useQuery("homeHeroImage", () => getImageWithCaption("homeHeroImage"))

  useEffect(() => {
    if (data) finishLoading()
  }, [data, finishLoading])

  if (isLoading) return

  return (
    <>
      <Head schema="siteMetadata" defaultPageName="Home" />
      <main className="page--home">
        <div className="grid">
          <div className="text-container">
            <div>Portfolio</div>
            <h1>Jess<br /> DeVitt</h1>
            <div>Visual Artist + Educator</div>
          </div>
          { error
          ? <img src={tierra} alt="Tierra" />
          : null
          }
          { data.map((item, index) =>
            item.caption
            ? <figure key={index}>
                <Image id={item.image.id} preview={item.image.preview} alt={item.image.alt} />
                <figcaption><PortableText value={item.caption} components={portableTextComponents} /></figcaption>
              </figure>
            : <Image key={index} id={item.image.id} preview={item.image.preview} alt={item.image.alt} />
          ) }
        </div>
      </main>
    </>
  )
}

export default Home
