import { useEffect } from "react"
import { useQuery } from "react-query"
import { PortableText } from "@portabletext/react"
import { getBlockContent, getImageWithCaption } from "../utils/api"
import { portableTextComponents } from "../utils/helpers"
import "./About.css"
import Head from "../components/Head"
import Error from "../components/Error"
import Image from "../components/Image"

function About (props) {
  const { finishLoading } = props
  const { isLoading: artistStatementIsLoading, error: artistStatementError, data: artistStatementData } = useQuery("aboutArtistStatement", () => getBlockContent("aboutArtistStatement"))
  const { isLoading: photoIsLoading, error: photoError, data: photoData } = useQuery("aboutPhoto", () => getImageWithCaption("aboutPhoto"))

  useEffect(() => {
    if (artistStatementData && photoData) finishLoading()
  }, [artistStatementData, photoData, finishLoading])

  if (artistStatementError) return <Error message={artistStatementError.message} />
  if (photoError) return <Error message={photoError.message} />
  if (artistStatementIsLoading || photoIsLoading) return

  return (
    <>
      <Head schema="aboutMetadata" defaultPageName="About" />
      <main className="page--about">
        <h1 className="sr-only">About</h1>
        <div className="wrapper-md grid">
          <div className="flow">
            { artistStatementData.map((item, index) =>
              <PortableText key={index} value={item.blockContent} components={portableTextComponents} />
            ) }
          </div>
          { photoData.map((item, index) =>
            item.caption
            ? <figure key={index}>
                <Image id={item.image.id} preview={item.image.preview} alt={item.image.alt} />
                <figcaption><PortableText value={item.caption} components={portableTextComponents} /></figcaption>
              </figure>
            : <Image key={index} id={item.image.id} preview={item.image.preview} alt={item.image.alt} />
          ) }
        </div>
      </main>
    </>
  )
}

export default About
